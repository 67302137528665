.footer {
    text-align: center;
    background-color: #ed6924;
    color: white;
    padding: 10px 0;
    margin-top: 20px;
}

.link {
    color: white;
}