.login-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(237, 105, 36);
}

.login-form-container {
    background-color: #042642;
    padding: 40px;
    border-radius: 40px;
    width: 600px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* İçeriği başlangıçta hizala */
    gap: 20px; /* Elemanlar arası boşluk */
}

/* Form elemanlarının yüksekliğini sabitle */
.ant-form-item {
    margin-bottom: 24px; /* Form elemanları arasındaki boşluğu sabitle */
}

/* Giriş butonunun stillerini güncelle */
.login-button, .ant-btn-primary {
    background-color: rgb(237, 105, 36);
    border-color: #042642;
    color: white;
}
