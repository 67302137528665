.header, .header-mobile {
    background: #042642;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 64px;
}

.logo-container {
    cursor: pointer;
}

.logo {
    height: 40px;
}

.menu {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.logout-button {
    background-color: #ed6924;
    border: none;
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #191919;
}

.ant-menu-horizontal {
    background-color: #042642;
    border-bottom: none;
}

.ant-menu-item-selected {
    color: #ed6924 !important;
}

.footer {
    background: #042642;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    padding: 0;
    margin: 0;
}

.bottom-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #042642;
    border: none;
}

.bottom-nav .ant-menu-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 4px;
    margin: 0 !important;
    height: auto;
    line-height: 1.2;
    font-size: 12px;
    text-align: center;
    border: none !important;
}

.bottom-nav .ant-menu-item .anticon {
    font-size: 20px;
    margin-bottom: 4px;
    color: #ed6924;
    display: block;
}

.bottom-nav .ant-menu-item-selected {
    background-color: #ed6924 !important;
    color: white !important;
    border-radius: 10px; /* Seçili öğe için köşeleri yuvarladım */
}

.bottom-nav .ant-menu-item-selected .anticon {
    color: white; /* Seçili öğedeki ikonun rengi */
}

.ant-menu-item {
    color: white !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.menu-button {
    color: white;
    background-color: #042642;
    border: none;
}

.drawer-menu {
    .ant-menu-item {
        background-color: #ed6924 !important;
        color: white !important;
    }
}

@media (max-width: 768px) {
    .header {
        display: none;
    }

    .header-mobile {
        display: flex;
    }

    .footer {
        display: block;
    }

    .bottom-nav .ant-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ant-menu-item-selected {
        background-color: #ed6924 !important;
        color: white !important;
    }
}
