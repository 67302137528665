/* Temel container stili */
.transactions-container {
    width: 100%;
    max-width: 1200px;
    margin: 64px auto 80px;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

/* Başlık stili */
h1 {
    text-align: center;
    color: #042642;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
}

/* Form container */
.transaction-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 600px;
    margin: 0 auto 32px;
    padding: 24px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

/* Form grup stili */
.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Label stili */
label {
    color: #042642;
    font-weight: 500;
    font-size: 14px;
}

/* Input stili */
input, select {
    padding: 10px 12px;
    border: 1px solid #e0e4e8;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
}

input:focus, select:focus {
    outline: none;
    border-color: #ed6924;
    box-shadow: 0 0 0 2px rgba(237, 105, 36, 0.1);
}

/* Button stilleri */
button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    background-color: #ed6924;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(237, 105, 36, 0.2);
}

button:hover {
    background-color: #d55a1c;
}

button:disabled {
    background-color: #e0e4e8;
    cursor: not-allowed;
    box-shadow: none;
}

/* Hata mesajı stili */
.error-message {
    color: #f1343f;
    text-align: center;
    margin-bottom: 20px;
    padding: 8px;
    background-color: #fff2f0;
    border-radius: 6px;
    font-size: 14px;
}

/* Tablo stilleri */
.transactions-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 24px;
    border-radius: 8px;
    overflow: hidden;
}

/* Tablo header ve hücre stilleri */
.transactions-table th,
.transactions-table td {
    padding: 12px 16px;
    border: 1px solid #e0e4e8;
}

.transactions-table th {
    background-color: #042642;
    color: white;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
}

/* Tablo satır stilleri */
.transactions-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.transactions-table tr:hover {
    background-color: #f5f7fa;
}

/* Mobil responsive düzenlemeler */
@media (max-width: 768px) {
    .transactions-container {
        padding: 16px;
        margin: 64px 12px 80px;
    }

    h1 {
        font-size: 20px;
        margin-bottom: 24px;
    }

    .transaction-form {
        padding: 16px;
    }

    button {
        padding: 10px 20px;
    }

    .transactions-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .transactions-table th,
    .transactions-table td {
        padding: 8px 12px;
        font-size: 13px;
    }
}

/* Tablo scroll çubuğu stilleri */
.transactions-table::-webkit-scrollbar {
    height: 8px;
}

.transactions-table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.transactions-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.transactions-table::-webkit-scrollbar-thumb:hover {
    background: #666;
}